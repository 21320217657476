import React from "react";
import { styled } from "../../stitches.config";
import { bodySmall } from "../commonStyles/Fonts";

import InfoCategoryIcon from "../assets/icons/info-category.svg"

type CategoryInfoSignProps = {
    infoSignText?: string;
};

export const CategoryInfoSign: React.FC<CategoryInfoSignProps> = ({
    infoSignText,
    ...props
}) => {
    return (
        <WrapperCategoryInfoSign>
            <Icon>
                <Info className={bodySmall()}>{infoSignText}</Info>
            </Icon>
        </WrapperCategoryInfoSign>
    );
};

const WrapperCategoryInfoSign = styled("div", {
    position: "relative"
});

const Icon = styled("div", {
    position: "relative",
    width: "24px",
    height: "24px",
    backgroundImage: `url("${InfoCategoryIcon}")`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    "&:hover div": {
        display: "block"
    }
});

const Info = styled("div", {
    position: "absolute",
    top: "-66px",
    background: "$grayscale100Fixed",
    color: "$grayscale0Fixed",
    width: "166px",
    left: "-142px",
    padding: "4px 6px",
    display: "none",
    "@bp4": {
        left: 0
    }
});

export default CategoryInfoSign;
